import { useState } from "react";
import {
  Card,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardHeader,
  Label,
  Container,
  FormGroup,
  Form,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "react-datepicker/dist/date_utils";
import "react-datepicker/dist/react-datepicker.css";
type EditModalProps = {
  showEditModal: boolean;
  toggleEditModal: Function;
};

export default function EditModal(props: EditModalProps) {
  const { showEditModal, toggleEditModal } = props;
  const [checkInDate, setCheckInDate] = useState<Date>(new Date()); //set to this assignments check-in time
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(new Date()); //set to this assignments check-in time
  const [driverPhone, setDriverPhone] = useState<string>("");
  const [driverName, setDriverName] = useState<string>("");
  const [snowRoute, setSnowRoute] = useState<string>("");

  async function updateDatebase() {
    //create update object
    //above state details
    //what assignment to update
    //need assignment ID
    //post to API
    //create API endpoint to post object to
  }
  //reactstrap wants it like this
  const toggle = () => {
    return toggleEditModal();
  };

  function onSubmit() {
    //create obj to be sent to backend
    //send to backend to update this entry
  }

  return (
    <Modal
      isOpen={showEditModal}
      toggle={toggle}
      centered
      size="lg"
      style={{
        maxWidth: "1000px",
        width: "100%",
        maxHeight: "800px",
        height: "100%",
      }}
    >
      <Form>
        <ModalHeader style={{}} toggle={toggle}>
          Edit Assignment
        </ModalHeader>
        <ModalBody>
          <Container>
            <Card>
              <CardHeader>Contractor Information</CardHeader>
              <CardBody>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta
                earum quibusdam necessitatibus beatae molestias cum, ipsum
                accusamus veritatis. Voluptatibus dolor quam in! Veniam est ex
                officia qui ullam esse pariatur.
              </CardBody>
            </Card>
            <br />
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Card
                style={{
                  maxWidth: "400px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <CardHeader className="text-center">
                  Driver and Route details
                </CardHeader>
                <CardBody style={{ width: "80%" }}>
                  <FormGroup floating>
                    <Input
                      value={driverName}
                      onChange={(e) => setDriverName(e.target.value)}
                      placeholder="Driver Full Name"
                      required
                    />
                    <Label>Driver Full Name</Label>
                  </FormGroup>

                  <FormGroup floating>
                    <Input
                      value={driverPhone}
                      onChange={(e) => setDriverPhone(e.target.value)}
                      placeholder="Driver Phone"
                      required
                    />
                    <Label>Driver Phone</Label>
                  </FormGroup>

                  <FormGroup>
                    <Label>Snow Route:</Label>
                    <Input
                      type="select"
                      value={snowRoute}
                      onChange={(e) => setSnowRoute(e.target.value)}
                    >
                      <option>1</option>
                      <option>2</option>
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card
                style={{
                  maxWidth: "400px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <CardHeader className="text-center">Shift Details</CardHeader>
                <CardBody style={{ width: "80%" }}>
                  <FormGroup>
                    <Label>Shift Type:</Label>
                    <Input
                      type="select"
                      value={snowRoute}
                      onChange={(e) => setSnowRoute(e.target.value)}
                    >
                      <option className="yellow">Yellow</option>
                      <option className="orange">Orange</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Driver Check-In Time:</Label>
                    <DatePicker
                      selected={checkInDate}
                      onChange={(date) => {
                        if (date != null) setCheckInDate(date);
                      }}
                      showTimeSelect
                      timeFormat="HH:mm:ss"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Driver Check-Out Time:</Label>
                    <DatePicker
                      minDate={checkInDate}
                      selected={checkOutDate}
                      onChange={(date) => {
                        if (date != null) setCheckOutDate(date);
                      }}
                      showTimeSelect
                      timeFormat="HH:mm:ss"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormGroup>
                  <div className="text-center">Shift Duration: {} hours</div>
                </CardBody>
              </Card>
            </Container>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="succes" type="submit" onClick={toggle}>
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
/*
              </div>
               */
