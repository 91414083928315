import { RouterProvider } from "react-router-dom";
import Router from "./utils/Router";
import { ViewModalProvider } from "./contexts/ViewModalContext";
function App() {
  return (
    <div className="App">
      <ViewModalProvider>
        <RouterProvider router={Router} />
      </ViewModalProvider>
    </div>
  );
}

export default App;
