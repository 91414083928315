export type FullInspection = {
  inspection: {
    id: number;
    inspector_username: string;
    vehicle_vin: string;
    vehicle_tag: string;
    shift_type: string;
    driver_name: string;
    driver_phone: string;
    inspec_result: {
      dpwt: boolean;
      avl: boolean;
      lights: boolean;
      plow: boolean;
      spreader: boolean;
      spreader_setting: boolean;
      pass: boolean;
      comments: string | null;
    };
    inspec_notes: string | null;
    photo: string | null;
    inspected_on: string;
  };
  vehicles: {
    contractor_id: number;
    avl: number;
    equipment: number;
    vehicle_type: string;
  };
  contractor: {
    id: number;
    company_name: string;
    street: string;
    city: string;
    address_state: string;
    postal_code: string;
    po_number: string | null;
    vendor: string | null;
    bid: string | null;
    contract: string | null;
    snowplan: string | null;
    stormtrak: string | null;
    district: string | null;
  };
  contractorContactInfo: {
    contact_name: string;
    contact_email: string | null;
    contact_cell: string | null;
    contact_office: string | null;
  };
  inspectorContactInfo: {
    inspector_name: string | null;
    email: string | null;
  };
  assignment: {
    shiftType: string;
    district: string;
    driver_name: string;
    driver_phone: string;
    route: string;
    time_out: string;
    time_in: string;
    duration: number;
    inspection_id: number;
  } | null;
};
export const exampleData: FullInspection = {
  inspection: {
    id: 10,
    inspector_username: "pixida",
    vehicle_vin: "VIN NUMBER",
    vehicle_tag: "TAG ",
    shift_type: "ORANGE",
    driver_name: "TESTING TESTER",
    driver_phone: "12346432423",
    inspec_result: {
      dpwt: true,
      avl: true,
      lights: true,
      plow: true,
      spreader: true,
      spreader_setting: true,
      pass: true,
      comments: "comments",
    },
    inspec_notes: null,
    photo: null,
    inspected_on: "2024-10-21T20:27:53.137Z",
  },
  vehicles: {
    contractor_id: 1,
    avl: 162,
    equipment: 1,
    vehicle_type: "4 WHEEL P/U",
  },
  contractor: {
    id: 1,
    company_name: "COMPANY NAME",
    street: "123 Street",
    city: "city",
    address_state: "state",
    postal_code: "zip code",
    po_number: null,
    vendor: "1000000131",
    bid: "WS456331161",
    contract: "4400006365",
    snowplan: null,
    stormtrak: null,
    district: null,
  },
  contractorContactInfo: {
    contact_name: "contact name",
    contact_email: "email@gmail.com",
    contact_cell: "(718)123-4567",
    contact_office: null,
  },
  inspectorContactInfo: {
    inspector_name: "chris",
    email: "email@gmail.com",
  },
  assignment: null,
};
