import { useEffect, useState } from "react";
import { Input, Container, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { authService } from "src/services/AuthService";
import logo from "src/assets/img/logo.png";
export default function LoginPage() {
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const result = await authService.signIn(username, password);
      if (result) {
        console.log("Sign in successful, Navigating to Dashboard");
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      alert(`Sign in failed: ${error}`);
    }
  };

  const handleSignUp = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      await authService.signUp(username, email, password);
      navigate("/confirm", { state: { email } });
    } catch (error) {
      alert(`Sign up failed: ${error}`);
    }
  };

  return (
    <div>
      <h1 className="text-center" style={{ backgroundColor: "#ffd200" }}>
        Prince George's County Snow Dashboard
      </h1>
      <Container
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={logo} alt="Welcome!" />
        <h4 className="">
          {/*   {isSignUp
            ? "Sign up to create an account"
            : "Sign in to your account"} */}
          Sign in to your account
        </h4>
        <form
          style={{
            maxWidth: "500px",
            width: "100%",
          }}
          onSubmit={isSignUp ? handleSignUp : handleSignIn}
        >
          <div>
            <Input
              className="inputText"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>

          {isSignUp && (
            <div>
              <Input
                className="inputText"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
          )}
          <div>
            <Input
              className="inputText"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          {isSignUp && (
            <div>
              <Input
                className="inputText"
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                required
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "5px",
            }}
          >
            <Button type="submit">{isSignUp ? "Sign Up" : "Sign In"}</Button>
          </div>
        </form>
        {/*      <button onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp
            ? "Already have an account? Sign In"
            : "Need an account? Sign Up"}
        </button> */}
      </Container>
      <br />
    </div>
  );
}
