import { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Label,
  Input,
  InputGroup,
  CardFooter,
  Button,
} from "reactstrap";
import {
  REDUCER_ACTION_TYPE,
  useViewModalDispatch,
  useViewModal,
} from "src/contexts/ViewModalContext";
import { ApiService } from "src/services/ApiService";
import { Assignment, blankAssignment } from "src/types/Assignment";
import {
  millisecondsToHours,
  millisecondsToMinutes,
  normalizeDBAssignment,
} from "src/utils/convert";

/*
  TODO
 - update backend to expect time in and out not check in and out
*/

type EditAssignmentProps = {
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

export function EditAssignment(props: EditAssignmentProps) {
  const { setEditToggle } = props;
  const viewModalDispatch = useViewModalDispatch();
  const viewModalContext = useViewModal();
  const [assignment, setAssignment] = useState<Assignment>(blankAssignment);
  const [checkIn, setCheckIn] = useState({
    date: "",
    time: "",
  });
  const [checkOut, setCheckOut] = useState({
    date: "",
    time: "",
  });
  const [duration, setDuration] = useState<number>(0);

  //Get assignment
  useEffect(() => {
    const { fullRecord } = viewModalContext;
    if (fullRecord.assignment != null) {
      const currAssignment = normalizeDBAssignment(fullRecord.assignment);
      setAssignment(currAssignment);
    }
    //else remains a blank assignment
  }, [viewModalContext]);

  useEffect(() => {
    let d1 = new Date(checkIn.date + "Z" + checkIn.time);
    let d2 = new Date(checkOut.date + "Z" + checkOut.time);

    setAssignment({
      ...assignment,
      time_in: d1,
      time_out: d2,
    });
    // Get the time difference in milliseconds
    let timeDiff: number = d2.getTime() - d1.getTime();
    setDuration(timeDiff);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIn, checkOut]);

  async function handleSave() {
    //save assignment to DB

    console.log("--assignment--", assignment);
    try {
      //build the assignment
      //TO DO CHANGE BACKEND EXPECTATION
      const currAssignment = {
        shiftType: assignment.shiftType,
        driverPhone: assignment.driver_phone,
        driverName: assignment.driver_name,
        route: assignment.route,
        duration: assignment.duration,
        district: Number(assignment.district),
        checkIn: isValidDate(assignment.time_in) //must update backend to expect time in and out
          ? assignment.time_in?.toISOString()
          : null,
        checkOut: isValidDate(assignment.time_out)
          ? assignment.time_out?.toISOString()
          : null,
        inspectionId: viewModalContext.inspection_id, //actual inspection id selected through table
      };

      //new assignment
      if (assignment.inspection_id === 0) {
        console.log("=============== NEW ===============");
        const response = await ApiService.post("/assignment", {
          assignment: currAssignment,
        });
        console.log(response);
        // if (response.status === 200) {
        // alertRef.current = { color: "", text: "Assignment Saved!" };
        // }
      } else {
        console.log("=============== EDIT ===============");
        // update existing assignment
        // const response = await ApiService.put("/assignment", {assignment:currAssignment});
        // if (response.status === 200) {
        //   alertRef.current = { color: "", text: "Changes Saved!" };
        // }
      }
      //dispatch new state to context
      viewModalDispatch({
        actionType: REDUCER_ACTION_TYPE.SET_INSPECTION,
        payload: {
          fullRecord: {
            ...viewModalContext.fullRecord,
            driver_name: assignment.driver_name,
            driver_phone: assignment.driver_phone,
            assignment: {
              ...assignment,
              inspection_id: viewModalContext.inspection_id,
            },
          },
        },
      });
      // setAlertVisible((prev) => !prev);
    } catch (error) {
      console.error(error);
      // alertRef.current = { color: "danger", text: "Unable to save changes!" };
    }
    setEditToggle((prev) => !prev);
  }

  // async function handleDelete() {
  //delete assigment
  // const response = await ApiService.delete("/assignment");
  // if (response.statusCode === 200) {
  // setEditToggle((prev) => !prev);
  // }
  // }
  return (
    <Card>
      <CardHeader>Assignment Report </CardHeader>
      <CardBody>
        <Form>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormGroup>
              <Label for="driver_name" size="sm">
                Driver Full Name:
              </Label>
              <Input
                id="driver_name"
                bsSize="sm"
                value={assignment?.driver_name}
                onChange={(e) =>
                  setAssignment({ ...assignment, driver_name: e.target.value })
                }
                placeholder={assignment?.driver_name}
                required
              />
              <Label for="driver_phone">Driver Phone: </Label>
              <Input
                id="driver_phone"
                bsSize="sm"
                value={assignment?.driver_phone}
                onChange={(e) =>
                  setAssignment({
                    ...assignment,
                    driver_phone: e.target.value,
                  })
                }
                placeholder={assignment?.driver_phone}
                required
              />
              <Label for="snowRoute">Route: </Label>
              <Input
                id="snowRoute"
                bsSize="sm"
                type="select"
                value={assignment?.route}
                onChange={(e) =>
                  setAssignment({
                    ...assignment,
                    route: e.target.value,
                  })
                }
                placeholder={assignment?.route}
                required
              >
                <option>1</option>
                <option>abc</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
            <FormGroup style={{ flexBasis: "55%" }}>
              <Label for="checkIn">Check-In: </Label>
              <InputGroup id="checkIn">
                <Input
                  type="date"
                  style={{ flexBasis: "12%" }}
                  value={checkIn.date}
                  onChange={(e) => {
                    setCheckIn({
                      ...checkIn,
                      date: e.target.value,
                    });
                  }}
                />
                <Input
                  type="time"
                  value={checkIn.time}
                  onChange={(e) =>
                    setCheckIn({ ...checkIn, time: e.target.value })
                  }
                />
              </InputGroup>
              <br />
              <Label for="checkOut">Check-Out: </Label>
              <InputGroup id="checkOut">
                <Input
                  type="date"
                  style={{ flexBasis: "10%" }}
                  value={checkOut.date}
                  onChange={(e) =>
                    setCheckOut({ ...checkOut, date: e.target.value })
                  }
                />
                <Input
                  type="time"
                  value={checkOut.time}
                  onChange={(e) =>
                    setCheckOut({ ...checkOut, time: e.target.value })
                  }
                />
              </InputGroup>
              <div className="text-center" style={{ paddingTop: "4%" }}>
                {typeof duration === "number" && duration >= 0 ? (
                  <div>
                    Duration: {millisecondsToHours(duration)} Hours and{" "}
                    {millisecondsToMinutes(duration)} Minutes
                  </div>
                ) : (
                  <></>
                )}{" "}
              </div>
            </FormGroup>
          </Container>
          <Container
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormGroup
              style={{
                flexGrow: "auto",
              }}
            >
              <Label for="shiftType">Shift-Type: </Label>
              <Input
                id="shiftType"
                bsSize="sm"
                type="select"
                value={assignment?.shiftType}
                onChange={(e) =>
                  setAssignment({ ...assignment, shiftType: e.target.value })
                }
                placeholder={assignment?.shiftType}
                required
              >
                <option style={{ backgroundColor: "yellow" }}>YELLOW</option>
                <option style={{ backgroundColor: "orange" }}>ORANGE</option>
              </Input>
            </FormGroup>
            <FormGroup
              style={{
                flexGrow: "auto",
              }}
            >
              <Label for="district">District: </Label>
              <Input
                id="district"
                bsSize="sm"
                type="select"
                value={assignment?.district}
                onChange={(e) =>
                  setAssignment({ ...assignment, district: e.target.value })
                }
                placeholder={assignment?.district}
                required
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </Container>
        </Form>
      </CardBody>
      <CardFooter style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          size="sm"
          color="warning"
          style={{ marginRight: "2%" }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          size="sm"
          color="warning"
          onClick={() => {
            setEditToggle((prev) => !prev);
            viewModalDispatch({
              actionType: REDUCER_ACTION_TYPE.SET_ASSIGNMENT,
              payload: null,
            });
          }}
        >
          Cancel
        </Button>
      </CardFooter>
    </Card>
  );
}

function isValidDate(d: any) {
  console.log(d);
  if (Object.prototype.toString.call(d) === "[object Date]") {
    if (isNaN(d)) {
      // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return false;
    }
    // date object is valid
    return true;
  }
  // not a date object
  return false;
}
