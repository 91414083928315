import { Container, Card, CardHeader, CardBody } from "reactstrap";
import AssignmentCard from "../AssignmentCard/AssignmentCard";
import { FullInspection } from "src/types/FullInspection";

type InspectionCardProps = {
  data: FullInspection;
};
export function InspectionCard(props: InspectionCardProps) {
  const { data } = props;
  return (
    <Container style={{ display: "flex" }}>
      <Container>
        <Card>
          <CardHeader className="text-center">
            Detailed Inspection Report
          </CardHeader>
          <CardBody>
            <div>
              <p>Inspector: {data?.inspectorContactInfo.inspector_name}</p>
              <div>Inspector Email: {data?.inspectorContactInfo.email}</div>
              <div>Shift-Type: {data?.inspection.shift_type}</div>
              <div>Vehicle VIN: {data?.inspection.vehicle_vin}</div>
              <div>Vehicle Tag: {data?.inspection.vehicle_tag}</div>
              <div>AVL #: {data?.vehicles.avl}</div>
              <br />
              <div>Inspection Date: {data?.inspection.inspected_on}</div>
              <p>Results</p>
              <div>DPWT: {data?.inspection.inspec_result.dpwt?.toString()}</div>
              <div>AVL: {data?.inspection.inspec_result.avl?.toString()}</div>
              <div>
                Lights: {data?.inspection.inspec_result.lights?.toString()}
              </div>
              <div>Plow: {data?.inspection.inspec_result.plow?.toString()}</div>
              <div>
                Spreader: {data?.inspection.inspec_result.spreader?.toString()}
              </div>
              <div>
                Spreader-Setting:{" "}
                {data?.inspection.inspec_result.spreader_setting?.toString()}
              </div>

              <div
                style={{
                  color: data?.inspection.inspec_result.pass ? "green" : "red",
                }}
              >
                Determination: {data?.inspection.inspec_result.pass?.toString()}
              </div>
              <div>Comments: {data?.inspection.inspec_result.comments}</div>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Container>
        <Card>
          <CardHeader className="text-center">
            Contractor Information
          </CardHeader>
          <CardBody
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{}}>
              <p>{data?.contractor.company_name}</p>
              <p>
                {data?.contractor.street}, {data?.contractor.city},{" "}
                {data?.contractor.address_state}, {data?.contractor.postal_code}
              </p>
            </div>
            <div style={{}}>
              <p>Contact Information</p>
              <div>Name: {data?.contractorContactInfo.contact_name}</div>
              <div>Email: {data?.contractorContactInfo.contact_email}</div>
              <div>Cell Number: {data?.contractorContactInfo.contact_cell}</div>
              <div>
                Office Number: {data?.contractorContactInfo.contact_office}
              </div>
            </div>
          </CardBody>
        </Card>
        <br />
        <AssignmentCard />
      </Container>
    </Container>
  );
}
