import { Card, CardBody, Button } from "reactstrap";
import plus from "../../../../../assets/img/plus.svg";
import {
  REDUCER_ACTION_TYPE,
  useViewModalDispatch,
} from "src/contexts/ViewModalContext";
import { blankAssignment } from "src/types/Assignment";

type AddAssignmentProps = {
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
};
export function AddAssignment(props: AddAssignmentProps) {
  const { setEditToggle } = props;
  const viewModalDispatch = useViewModalDispatch();
  return (
    <Card>
      <CardBody
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        Add Assignment{" "}
        <Button
          color="link"
          onClick={(e) => {
            setEditToggle((prev) => !prev);
            viewModalDispatch({
              actionType: REDUCER_ACTION_TYPE.SET_ASSIGNMENT,
              payload: blankAssignment,
            });
          }}
        >
          <img
            src={plus}
            style={{ width: "50%" }}
            alt="press to add an assignment"
          />
        </Button>
      </CardBody>
    </Card>
  );
}
