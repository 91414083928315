import { createBrowserRouter, Navigate } from "react-router-dom";
import LoginPage from "../modules/LoginPage/LoginPage";
import DashboardPage from "../modules/DashboardPage/DashboardPage";
//import HomePage from "src/modules/HomePage/Home";
import Page404 from "../modules/ErrorPages/Page404";
import MainContent from "src/modules/DashboardPage/components/MainContent";
import MainLayout from "src/modules/DashboardPage/components/MainLayout";
import PrivateRoute from "./PrivateRoute";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { index: true, element: <Navigate to="dashboard" replace /> },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <MainContent />,
            children: [
              {
                path: "dashboard",
                element: <DashboardPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
]);
export default router;
