import {
  REDUCER_ACTION_TYPE,
  useViewModalDispatch,
} from "src/contexts/ViewModalContext";
import { Shift } from "src/types/Shifts";

type TableRowProps = {
  shift: Shift;
  toggleEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleViewModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function TableRow(props: TableRowProps) {
  const { shift, toggleEditModal, toggleViewModal } = props;

  const viewModalDispatch = useViewModalDispatch();
  function handleViewClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    viewModalDispatch({
      actionType: REDUCER_ACTION_TYPE.SET_ID,
      payload: Number(shift.inspection_id),
      // payload: 10,
    });
    toggleViewModal((prev) => !prev);
  }
  //to do refactor
  const parsedResult = JSON.parse(shift.inspec_result);
  return (
    <tr
      className="text-center"
      style={{ backgroundColor: !parsedResult.pass ? "#e74c3c" : "" }}
    >
      <td>
        <a onClick={(e) => handleViewClick(e)} className="btnuw edit">
          VIEW
        </a>
        {/* <a
          onClick={() => toggleEditModal((prev) => !prev)}
          className="btnuw edit"
        >
          EDIT
        </a> */}
      </td>
      <td>
        <p>{shift.vehicle_tag}</p>
      </td>
      <td>
        <p>contractor name</p>
      </td>
      <td>
        <div className="shift">
          {shift.shift_type == null ? (
            <p>Missing Shift Value</p>
          ) : shift.shift_type === "YELLOW" ? (
            <p className="yellow">Yellow</p>
          ) : (
            <p className="orange">Orange</p>
          )}
        </div>
      </td>
      <td className="tc">
        <p>equipNum</p>
      </td>
      <td className="tc">
        {/* <img src="" alt="" /> */}
        <p>{parsedResult?.pass ? "PASS" : "FAIL"}</p>
      </td>

      <td className="tc">
        <p>{shift.district}</p>
      </td>

      <td>{shift.driver_name}</td>
      <td>{shift.driver_phone}</td>
      <td>{shift.assignment_state}</td>
      <td>
        <p>ticketNum</p>
      </td>

      <td>
        <p>{shift.time_in}</p>
      </td>
      <td>
        <p>{shift.time_out}</p>
      </td>
      <td>
        <p>0</p>
      </td>
    </tr>
  );
}
