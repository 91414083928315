import {
  createContext,
  useContext,
  useReducer,
  useRef,
  ReactElement,
  Dispatch,
} from "react";
import { FullInspection, exampleData } from "src/types/FullInspection";

//Define Reducer actions
export const enum REDUCER_ACTION_TYPE {
  SET_ID,
  SET_INSPECTION,
  SET_ASSIGNMENT,
}
type ReducerAction = {
  actionType: REDUCER_ACTION_TYPE;
  payload: any;
};
type ModalState = {
  fullRecord: FullInspection;
  inspection_id: number;
};
const initState: ModalState = {
  fullRecord: exampleData,
  inspection_id: exampleData.inspection.id,
};

const ViewModalContext = createContext<ModalState>(initState);
const ViewModalDispatchContext = createContext<Dispatch<ReducerAction>>(
  () => {}
);
type ChildrenType = { children?: ReactElement | ReactElement[] };

export function ViewModalProvider({ children }: ChildrenType): ReactElement {
  const [state, dispatch] = useReducer(ViewModalReducer, initState);
  return (
    <ViewModalContext.Provider value={state}>
      <ViewModalDispatchContext.Provider value={dispatch}>
        {children}
      </ViewModalDispatchContext.Provider>
    </ViewModalContext.Provider>
  );
}
export function useViewModal() {
  return useContext(ViewModalContext);
}

export function useViewModalDispatch() {
  return useContext(ViewModalDispatchContext);
}

function ViewModalReducer(
  state: ModalState,
  action: ReducerAction
): typeof initState {
  switch (action.actionType) {
    case REDUCER_ACTION_TYPE.SET_ID: {
      if (Number(action.payload))
        return { ...state, inspection_id: action.payload };
      else return state;
    }
    case REDUCER_ACTION_TYPE.SET_INSPECTION: {
      return { ...state, ...action.payload };
    }
    case REDUCER_ACTION_TYPE.SET_ASSIGNMENT: {
      console.log(action.payload);
      return {
        ...state,
        fullRecord: { ...state.fullRecord, assignment: action.payload },
      };
    }
    default: {
      throw Error("Unknown action: " + action.actionType);
    }
  }
}
