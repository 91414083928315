import { useEffect, useState } from "react";
import {
  CloseButton,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { ApiService } from "src/services/ApiService";
import {
  REDUCER_ACTION_TYPE,
  useViewModal,
  useViewModalDispatch,
} from "src/contexts/ViewModalContext";
import { FullInspection } from "src/types/FullInspection";
import { exampleData } from "../../../../types/FullInspection";
import { normalizeDBAssignment } from "src/utils/convert";
import { InspectionCard } from "./InspectionCard/InspectionCard";
import { PhotoCard } from "./PhotoCard/PhotoCard";

//TO DO
//REVIEW MODAL SIZE STYLING

type ViewModalProps = {
  showViewModal: boolean;
  toggleViewModal: Function;
};
export default function ViewModal(props: ViewModalProps) {
  const { toggleViewModal, showViewModal } = props;
  const viewModalContext = useViewModal();
  const viewModalDispatch = useViewModalDispatch();
  const [data, setData] = useState<FullInspection>(exampleData);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    async function getData() {
      try {
        const response = await ApiService.get(
          `/inspection/${viewModalContext.inspection_id}`
        );
        setData(response.data);
        viewModalDispatch({
          actionType: REDUCER_ACTION_TYPE.SET_INSPECTION,
          payload: {
            fullRecord: {
              ...response.data,
              assignment:
                response.data.assignment === null
                  ? null
                  : normalizeDBAssignment(response.data.assignment),
            },
            inspectionId: response.data.inspection.id,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error(error);
        setData(exampleData);
      }
    }
    getData();
  }, [viewModalContext.inspection_id, viewModalDispatch]);
  const toggle = () => {
    return toggleViewModal();
  };
  return (
    <Modal
      isOpen={showViewModal}
      toggle={toggle}
      centered
      size="lg"
      style={{
        minWidth: "1200px",
        width: "100%",
      }}
    >
      <Nav tabs style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <NavItem>
            <NavLink
              active={activeTab === 1 ? true : false}
              onClick={() => {
                setActiveTab(1);
              }}
            >
              Inspection and Assignment Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 2 ? true : false}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              Photo
            </NavLink>
          </NavItem>
        </div>
        <NavItem style={{ paddingTop: "0.5%", paddingRight: "0.5%" }}>
          <CloseButton onClick={toggle}></CloseButton>
        </NavItem>
      </Nav>
      <ModalBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <InspectionCard data={data} />
          </TabPane>
          <TabPane tabId={2}>
            <PhotoCard />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}
