export type DBAssignment = {
  shiftType: string;
  district: string;
  driver_name: string;
  driver_phone: string;
  route: string;
  time_out: string;
  time_in: string;
  duration: number; //delete
  inspection_id: number;
};
export type Assignment = {
  shiftType: string;
  district: string;
  driver_name: string;
  driver_phone: string;
  route: string;
  time_out: Date | null;
  time_in: Date | null;
  duration: number;
  inspection_id: number;
};

//TO DO remove the temp values and fix the bigger problem in assignment
export const blankAssignment = {
  inspection_id: 0,
  shiftType: "YELLOW",
  district: "1",
  driver_name: "",
  driver_phone: "",
  route: "route",
  time_out: null,
  time_in: null,
  duration: 0,
};
