import TableRow from "./TableRow";
import { Shifts, Shift } from "src/types/Shifts";

type TablePropsType = {
  tableData: Shifts;
  toggleEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleViewModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Table(props: TablePropsType) {
  const { tableData, toggleEditModal, toggleViewModal } = props;
  console.log("- table data -", tableData);
  const colHeaders: string[] = [
    "View Details",
    "Tag #",
    "Contractor Name",
    "Shift Type",
    "Equip #",
    "Inspection Result",
    "Snow District",
    "Driver Name",
    "Driver Phone",
    "Assignment Details",
    "Ticket #",
    "Time-In",
    "Time-Out",
    "Total Hours",
  ];
  return (
    <table width="100%">
      <thead>
        <tr className="headings">
          {colHeaders.map((col, index) => {
            return (
              <td key={index}>
                <p>{col}</p>
              </td>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tableData.map((shift: Shift, index: number) => {
          return (
            <TableRow
              toggleEditModal={toggleEditModal}
              toggleViewModal={toggleViewModal}
              shift={shift}
              key={index}
            />
          );
        })}
      </tbody>
    </table>
  );
}
