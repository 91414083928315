import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";
import "../styles/DashboardPage.css";

export default function MainContent() {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <main>
        {showSideBar ? <SideBar /> : <></>}
        <Outlet />
      </main>
    </>
  );
}
