import arrowDown from "../../../../assets/img/arrow_down.svg";

export default function DataDownload() {
  return (
    <div className="filter-item col4">
      <div className="dropdown-block ul-block">
        <div className="dropdown va click">
          <p className="fs-15">
            <strong>Coming Soon!</strong>
          </p>
        </div>
      </div>
      <a href="#" className="btnuw">
        Download Data
      </a>
    </div>
  );
}
