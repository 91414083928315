import { DBAssignment } from "src/types/Assignment";

export function millisecondsToHours(milliseconds: number) {
  // Convert the time difference to total minutes
  let diffInMinutes: number = milliseconds / (1000 * 60); // 1000 ms * 60 seconds = 60,000 ms in 1 minute
  // Calculate hours and remaining minutes
  let hours: number = Math.floor(diffInMinutes / 60); // Get full hours
  return hours;
}
export function millisecondsToMinutes(milliseconds: number) {
  // Convert the time difference to total minutes
  let diffInMinutes: number = milliseconds / (1000 * 60); // 1000 ms * 60 seconds = 60,000 ms in 1 minute
  // Calculate hours and remaining minutes
  let minutes: number = Math.floor(diffInMinutes % 60); // Get the remaining minutes
  return minutes;
}

//TO DO REFACTOR VAR NAMES IN BACKEND
export function normalizeDBAssignment(assignment: DBAssignment) {
  console.log(assignment);
  return {
    ...assignment,
    time_in: assignment.time_in != null ? new Date(assignment.time_in) : null,
    time_out:
      assignment.time_out != null ? new Date(assignment.time_out) : null,
  };
}
