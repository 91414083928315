import { useState } from "react";
import { useViewModal } from "src/contexts/ViewModalContext";
import { AddAssignment } from "./AddAssignment";
import { DisplayAssignment } from "./DisplayAssignment";
import { EditAssignment } from "./EditAssignment";

export default function AssignmentCard() {
  //get current assignment
  const viewModalContext = useViewModal();
  const { fullRecord } = viewModalContext;
  const { assignment } = fullRecord;
  const [editToggle, setEditToggle] = useState<boolean>(false);

  if (assignment === null)
    return <AddAssignment setEditToggle={setEditToggle} />;
  if (!editToggle) return <DisplayAssignment setEditToggle={setEditToggle} />;
  else return <EditAssignment setEditToggle={setEditToggle} />;
}
