import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  GlobalSignOutCommand,
  AuthenticationResultType,
} from "@aws-sdk/client-cognito-identity-provider";
import CryptoJS from "crypto-js";
import config from "../config.json";
import { GlobalSignOutCommandInput } from "@aws-sdk/client-cognito-identity-provider";

export default class AuthService {
  private readonly cognitoClient: CognitoIdentityProviderClient;
  private isAuthenticated: boolean = false;
  private session: AuthenticationResultType | null = null;

  constructor() {
    this.cognitoClient = new CognitoIdentityProviderClient({
      region: config.region,
    });
    // Check session on initialization
    this.isAuthenticated = !!sessionStorage.getItem('idToken');
  }

  isUserAuthenticated() {
    return !!sessionStorage.getItem('idToken');
  }

  signIn = async (username: string, password: string) => {
    try {
      const command = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: config.clientId,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          SECRET_HASH: this.generateSecretHash(username),
        },
      });

      const { AuthenticationResult } = await this.cognitoClient.send(command);
      if (AuthenticationResult) {
        this.session = AuthenticationResult;
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken || "");
        sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || "");
        sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || "");
        this.isAuthenticated = true;
        return AuthenticationResult;
      }
    } catch (error) {
      console.error("Error signing in: ", error);
      throw error;
    }
  };

  signOut = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      if (accessToken) {
        const input: GlobalSignOutCommandInput = {
          AccessToken: accessToken
        };
        const command = new GlobalSignOutCommand(input);
        await this.cognitoClient.send(command);
      }
    } catch (error) {
      console.error(error);
    } finally {
      sessionStorage.clear();
      this.isAuthenticated = false;
    }
  };

  signUp = async (username: string, email: string, password: string) => {
    const params = {
      ClientId: config.clientId,
      Username: username,
      Password: password,
      SecretHash: this.generateSecretHash(username),
      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
      ],
    };
    try {
      const command = new SignUpCommand(params);
      const response = await this.cognitoClient.send(command);
      console.log("Sign up success: ", response);
      return response;
    } catch (error) {
      console.error("Error signing up: ", error);
      throw error;
    }
  };

  confirmSignUp = async (username: string, code: string) => {
    const params = {
      ClientId: config.clientId,
      Username: username,
      ConfirmationCode: code,
      SecretHash: this.generateSecretHash(username),
    };
    try {
      const command = new ConfirmSignUpCommand(params);
      await this.cognitoClient.send(command);
      console.log("User confirmed successfully");
      return true;
    } catch (error) {
      console.error("Error confirming sign up: ", error);
      throw error;
    }
  };

  private generateSecretHash = (username: string) => {
    const secretKey = config.clientSecret;
    const message = username + config.clientId;

    // Generate HMAC SHA-256
    const hash = CryptoJS.HmacSHA256(message, secretKey);

    // Base64 encode the hash
    const base64EncodedHash = hash.toString(CryptoJS.enc.Base64);
    return base64EncodedHash;
  };
}
export const authService = new AuthService();
