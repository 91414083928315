import { useState, useEffect } from "react";
import DataDownload from "./components/DataDownload/DataDownload";
import DistrictFilter from "./components/DistrictFilter/DistrictFilter";
import Table from "./components/Table/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApiService } from "src/services/ApiService";
import EditModal from "./components/EditModal/EditModal";
import ViewModal from "./components/ViewModal/ViewModal";
import exampleTableData from "./data/exampleTableData.json";
import { Label, ButtonGroup, Button } from "reactstrap";
import { Shifts } from "src/types/Shifts";

export default function DashboardPage() {
  //component handling state
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<Shifts>([]);
  const [data, setData] = useState<Shifts>([]);
  const [startDate, setStartDate] = useState(new Date("2024-10-23T10:27:53Z"));
  const [endDate, setEndDate] = useState(new Date());

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);

  const [shiftFilter, setShiftFilter] = useState<string[]>([]);

  //revisit
  useEffect(() => {
    setIsLoading(false);
  }, [tableData]);

  //Table Data based on shift filter
  useEffect(() => {
    if (shiftFilter.length <= 0) {
      setTableData(data);
      return;
    }
    //filter data for table
    const filtered = data.filter((shift) =>
      shiftFilter.includes(shift.shift_type)
    );
    setTableData(filtered);
  }, [data, shiftFilter]);

  function toggleEditModal() {
    setShowEditModal((prev) => !prev);
  }
  function toggleViewModal() {
    setShowViewModal((prev) => !prev);
  }

  function onShiftFilterBtnClick(selected: string) {
    const index = shiftFilter.indexOf(selected);
    if (index < 0) {
      shiftFilter.push(selected);
    } else {
      shiftFilter.splice(index, 1);
    }
    setShiftFilter([...shiftFilter]);
  }

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  async function handleSearch() {
    try {
      const { data } = await ApiService.get("/inspections", {
        params: { startDate, endDate },
      });
      console.log("data", data);
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section id="main-content">
      <h6>
        Prince George's County Departament of Public Works & Transportation
      </h6>
      <EditModal
        showEditModal={showEditModal}
        toggleEditModal={toggleEditModal}
      />
      <ViewModal
        showViewModal={showViewModal}
        toggleViewModal={toggleViewModal}
      />
      <div className="block">
        <div className="filter va">
          <div className="va filter-item">
            <DatePicker
              minDate={startDate}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
            <button className="btnuw ml" onClick={handleSearch}>
              Search
            </button>
          </div>
          {/* <DistrictFilter /> */}
          <div
            className="filter-item"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Label for="shiftsFilter" style={{ fontSize: "1.5vh" }}>
              Filter Shifts:
            </Label>
            <ButtonGroup size="md" id="shiftsFilter">
              <Button
                color="warning"
                outline
                onClick={() => onShiftFilterBtnClick("YELLOW")}
                active={shiftFilter.includes("YELLOW")}
              >
                Yellow
              </Button>
              <Button
                color="warning"
                outline
                onClick={() => onShiftFilterBtnClick("ORANGE")}
                active={shiftFilter.includes("ORANGE")}
              >
                Orange
              </Button>
            </ButtonGroup>
          </div>

          <DataDownload />
          {isLoading ? (
            <h1>Loading...</h1>
          ) : (
            <Table
              tableData={tableData}
              toggleEditModal={toggleEditModal}
              toggleViewModal={toggleViewModal}
            />
          )}
        </div>
      </div>
    </section>
  );
}
