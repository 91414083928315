import { Card, CardHeader, Button, CardBody } from "reactstrap";
import { useViewModal } from "src/contexts/ViewModalContext";
import {
  millisecondsToHours,
  millisecondsToMinutes,
  normalizeDBAssignment,
} from "src/utils/convert";
import { AddAssignment } from "./AddAssignment";

type DisplayAssignmentProps = {
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DisplayAssignment(props: DisplayAssignmentProps) {
  const { setEditToggle } = props;
  const viewModalContext = useViewModal();
  const { fullRecord } = viewModalContext;
  if (fullRecord.assignment === null)
    return <AddAssignment setEditToggle={setEditToggle} />;

  const assignment = normalizeDBAssignment(fullRecord.assignment);
  console.log(assignment);
  return (
    <Card>
      <CardHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Assignment Report
        <div>
          <Button color="link" onClick={() => setEditToggle((prev) => !prev)}>
            Edit
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        {assignment != null ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{}}>
              <div>Driver Full Name: {assignment.driver_name}</div>
              <div>Phone Number: {assignment.driver_phone}</div>
              <div>Shift: {assignment.shiftType}</div>
              <div>District: {assignment.district}</div>
            </div>
            <div style={{}}>
              <div>Route: {assignment.route}</div>
              <div>
                Check-In:{" "}
                {assignment.time_in === null
                  ? ""
                  : assignment.time_in.toLocaleString("en-US", {
                      timeZone: "UTC",
                    })}
              </div>
              <div>
                Check-Out:{" "}
                {assignment.time_out === null
                  ? ""
                  : assignment.time_out.toLocaleString("en-US", {
                      timeZone: "UTC",
                    })}
              </div>
              <div>
                Duration: {millisecondsToHours(assignment?.duration)} Hours and{" "}
                {millisecondsToMinutes(assignment?.duration)} Minutes
              </div>
            </div>
            <div style={{}}></div>
          </div>
        ) : (
          <p>No Assignment Given</p>
        )}
      </CardBody>
    </Card>
  );
}
